<template>
  <div class="container-fluid">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <div class="hello">Vocabulary List</div>
          </CCol>
            <CCol col="2">
            <vue-excel-xlsx
              class="vue-excel-btn"
              :data="item"
              :columns="columns"
              :file-name="'Vocabulary List'"
              :file-type="'xlsx'"
              :sheet-name="'Vocabulary List'"
              >Export Data</vue-excel-xlsx
            >
          </CCol>
          <!-- <CCol col="2">
            <CButton block @click="exportData(item)" color="dark"
              >Export Excel</CButton
            >
          </CCol> -->
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :hover="true"
          :striped="true"
          :items="item"
          :fields="fields"
          :border="true"
          :column-filter="true"
          :table-filter="true"
          :items-per-page="20"
          :items-per-page-select="true"
          pagination
          sorter
        >
          <template #status="{ item }">
            <td>
              <span>
                <CButton
                  block
                  router-link
                  :to="'/vocabularydetails/' + item._id"
                  color="primary"
                  >View More</CButton
                >
              </span>
            </td>
          </template>
          <template #createdAt="{ item }">
            <td>
              {{ $formatDate(item.createdAt) }}
            </td>
          </template>
          <!-- <template #firstName="{ item }">
            <td>
              {{ getFilteredData(item.userId).firstName }}
            </td>
          </template>
          <template #lastName="{ item }">
            <td>
              {{ getFilteredData(item.userId).lastName }}
            </td>
          </template>
          <template #email="{ item }">
            <td>
              {{ getFilteredData(item.userId).email }}
            </td>
          </template> -->
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import APIService from "@/services/api.service.js";

export default {
  name: "list-vocabulary",
  data() {
    return {
      item: [],
      userItem: [],
       columns: [
        {
          label: "Title",
          field: "title",
        },
         {
          label: "First Name",
          field: "userFirstName",
        },
        {
          label: "Last Name",
          field: "userLastName",
        },
        {
          label: "Email",
           field: "userEmail",
        },
      ],
      fields: [
        {
          key: "title",
          label: "Title",
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "userFirstName",
          label: "First Name",
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "userLastName",
          label: "Last Name",
          sortable: true,
        },
        {
          key: "userEmail",
          label: "Email",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Date",
          sortable: true,
           type: 'datetime',

           displayFormat: 'dd/MM/yyyy',
        },
        {
          key: "status",
          label: "Action",
          sortable: true,
          filter: false,
        },
      ],
      userId: this.$store.state.userInfo.userId,
      email: this.$store.state.userInfo.email,
    };
  },
  created() {
    this.getUserListData();
    this.getVocabularyListData();
   
    document.title = this.$store.state.projecttitle + " - Vocabulary List";
  },
  methods: {
    getVocabularyListData() {
      APIService.getVocabulary("/vocabularies/list", null).then(
        ((response) => {
          this.$set(this, "item", response.data);
        }).bind(this)
      );
    },

    // getFilteredData(userId) {
    //   var _filtered = this.userItem.filter((x) => x._id == userId);
    //   return _filtered.length > 0 ? _filtered[0] : {};
    // },

    // exportData(ids) {
    //   let array = [];
    //   for (let i = 0; i < ids.length; i++) {
    //     var id = ids[i]._id;
    //     array.push(id);
    //   }
    //   console.log(array);
    //   let data = {
    //     arrayOfIds: array,
    //     emailUser: this.email,
    //   };
    //   APIService.post("/vocabularies/exportvocabulary", data).then(
    //     (response) => {
    //       console.log(response);
    //     }
    //   );
    // },

    getUserListData() {
      APIService.get("/users/userlist").then(
        ((response) => {
          this.$set(this, "userItem", response.data);
        }).bind(this)
      );
    },
  },
};
</script>
<style scoped>
.vue-excel-btn {
  outline: none;
  display: block;
  width: 100%;
      color: #fff;
    background-color: #636f83;
    border-color: #636f83;
  font-weight: 400;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  
}
</style>